import { DcBaseComponent } from '@deleteagency/dc';

export default class InsightsPodcastsComponents extends DcBaseComponent {
    static getNamespace() {
        return 'insights-podcasts';
    }

    onInit() {
        if (typeof window !== 'undefined') {
            this._storeOriginalSeasonInfo();
            this._updateSeasonInfo();
            window.addEventListener('resize', this._updateSeasonInfo.bind(this));
        }
    }

    /**
     * Stores the original season information for podcast items.
     *
     * @private
     */
    _storeOriginalSeasonInfo() {
        const podcastSeasonInfo = document.querySelectorAll(
            '.insights-podcasts__podcasts-list_item__details'
        );
        podcastSeasonInfo.forEach((info) => {
            info.dataset.originalText = info.textContent;
        });
    }

    /**
     * Updates the season information for podcast items based on the window width.
     * If the window width is less than 768 pixels, it converts their season information.
     * If the window width is 768 pixels or more, it restores the original season information.
     *
     * @private
     */
    _updateSeasonInfo() {
        const podcastSeasonInfo = document.querySelectorAll(
            '.insights-podcasts__podcasts-list_item__details'
        );
        if (window.innerWidth < 768) {
            podcastSeasonInfo.forEach((info) => {
                this._convertSeasonInfo(info);
            });
        } else {
            podcastSeasonInfo.forEach((info) => {
                this._restoreOriginalSeasonInfo(info);
            });
        }
    }

    /**
     * Converts the season and episode information in the provided element's text content.
     * Replaces occurrences of "Season " with "S" and "Episode " with "E".
     *
     * @param {HTMLElement} info - The HTML element containing the season and episode information.
     */
    _convertSeasonInfo(info) {
        info.textContent = info.textContent.replace(/Season /g, 'S').replace(/Episode /g, 'E');
    }

    /**
     * Restores the original season and episode information in the provided element's text content.
     *
     * @param {HTMLElement} info - The HTML element containing the season and episode information.
     */
    _restoreOriginalSeasonInfo(info) {
        info.textContent = info.dataset.originalText;
    }
}
