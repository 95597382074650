import { AxiosSingular } from '@deleteagency/axios-singular';
import axios from 'axios';
import { EnhancedImage } from 'components/enhanced-image';
import PropListCarouselComponent from 'components/props-panel/js/prop-panel-carousel.component';
import analyticsService from 'general/js/analytics-service';
import api from 'general/js/api';
import ArrayHelper from 'general/js/array-helper';
import { transformRequestOptions } from 'general/js/url-helper';
import React from 'preact/compat';
import propTypes from 'prop-types';
import OnVisible from 'react-on-visible';

const RECENTLY_VIEWED_PROPERTIES_KEY = 'winkworth_recently_viewed_properties_key';
class RecentlyViewedProperties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            properties: [],
            isFetching: false,
        };
        this.axiosSingular = new AxiosSingular(api, axios.CancelToken, axios.isCancel);
        this.carousel = null;
        this.carouselContainer = React.createRef();
        this.isActivated = false;
    }

    onVisible = async (visible) => {
        if (this.isActivated) return;

        if (!this.isKeyExists()) return;

        if (visible) {
            const propertyIds = this.getViewedPropertyIds();

            if (propertyIds.length > 0) {
                await this.fetchProperties(propertyIds);
            }

            if (this.carouselContainer.current) {
                this.carousel = new PropListCarouselComponent(this.carouselContainer.current);

                this.carousel.onInit();

                this.isActivated = true;
            }
        }
    };

    isKeyExists = () => localStorage.getItem(RECENTLY_VIEWED_PROPERTIES_KEY) !== null;

    getViewedPropertyIds = () => JSON.parse(localStorage.getItem(RECENTLY_VIEWED_PROPERTIES_KEY));

    async fetchProperties(propertyIds) {
        this.setState({ isFetching: true });

        return this.axiosSingular
            .get(this.props.endpointUrl, {
                params: { propertyIds },
                paramsSerializer: (params) => transformRequestOptions(params),
            })
            .then(
                (response) => {
                    this.setState({
                        properties: response.data.properties,
                        isFetching: false,
                    });
                },
                (error) => {
                    if (!axios.isCancel(error)) {
                        // eslint-disable-next-line no-console
                        console.error('Actual error!', error);
                    }
                }
            );
    }

    onClick = ($property) => (e) => {
        analyticsService.sendEvent(document.title, 'Recently Viewed Properties', $property);
    };

    render() {
        const { properties } = this.state;
        const { title } = this.props;

        const RecentlyViewedPropertiesBanner = document.querySelector('.recent-properties-banner');
        if (RecentlyViewedPropertiesBanner) {
            if (properties.length > 0) {
                RecentlyViewedPropertiesBanner.classList.remove('hidden');
            }
        }

        return (
            <OnVisible onChange={this.onVisible}>
                {!ArrayHelper.isEmpty(properties) && (
                    // only render the first 2 items in the array
                    <div className="recently_viewed_properties">
                        <header className="recently_viewed_properties__header">
                            <h2 className="recently_viewed_properties__title">{title}</h2>
                        </header>
                        <div className="recently_viewed_properties__content">
                            {properties.slice(0, 2).map((property) => {
                                const {
                                    title,
                                    propertyId,
                                    urlToDetails,
                                    bedrooms,
                                    bathrooms,
                                    receptionRooms,
                                    propertyType,
                                    priceInformation: {
                                        price,
                                        isPriceOnApplication,
                                        priceQualifier,
                                        tenure,
                                    },
                                    image: { url: imageUrl },
                                    shortDescription,
                                } = property;
                                const showSpecs =
                                    bedrooms !== 0 || bathrooms !== 0 || receptionRooms !== 0;
                                const propType = propertyType ? propertyType : '';
                                const tag =
                                    bedrooms !== 0
                                        ? `${bedrooms} bedroom ${propType}`
                                        : propType == ''
                                        ? `${property.propertyChannel} property`
                                        : propType !== 'House'
                                        ? `${propType}`
                                        : '';

                                const formattedprice = price
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                                return (
                                    <a
                                        className="recently_viewed_properties__link"
                                        key={propertyId}
                                        href={urlToDetails}
                                        onClick={this.onClick(title)}
                                        aria-labelledby={propertyId}
                                    >
                                        <article className="recently_viewed_properties__propertyCard">
                                            <div className="recently_viewed_properties__propertyCard--left">
                                                <div className="recently_viewed_properties__propertyCard__content">
                                                    <div className="recently_viewed_properties__propertyCard__info">
                                                        {tag && (
                                                            <div className="recently_viewed_properties__propertyCard__tag">
                                                                {tag}
                                                            </div>
                                                        )}
                                                        <div
                                                            className="recently_viewed_properties__propertyCard__title"
                                                            id={propertyId}
                                                        >
                                                            {title}
                                                        </div>
                                                        <div className="recently_viewed_properties__propertyCard__priceContainer">
                                                            {isPriceOnApplication ? (
                                                                <span className="recently_viewed_properties__propertyCard__price_text">
                                                                    {priceQualifier}
                                                                </span>
                                                            ) : (
                                                                <>
                                                                    <span className="recently_viewed_properties__propertyCard__price">
                                                                        &pound;{formattedprice}
                                                                    </span>
                                                                    <span className="recently_viewed_properties__propertyCard__price_text">
                                                                        {priceQualifier || tenure}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    {showSpecs && (
                                                        <div className="recently_viewed_properties__propertyCard__specs">
                                                            <ul className="specs">
                                                                {bedrooms !== 0 && (
                                                                    <li className="specs__item">
                                                                        <svg
                                                                            width="21"
                                                                            height="14"
                                                                            className="specs__icon"
                                                                            role="img"
                                                                        >
                                                                            <title>Bedrooms</title>
                                                                            <use xlinkHref="#icon-bed"></use>
                                                                        </svg>
                                                                        <span className="specs__text">
                                                                            {bedrooms}
                                                                        </span>
                                                                    </li>
                                                                )}
                                                                {bathrooms !== 0 && (
                                                                    <li className="specs__item">
                                                                        <svg
                                                                            width="20"
                                                                            height="19"
                                                                            className="specs__icon"
                                                                            role="img"
                                                                        >
                                                                            <title>Bathrooms</title>
                                                                            <use xlinkHref="#icon-bath"></use>
                                                                        </svg>
                                                                        <span className="specs__text">
                                                                            {bathrooms}
                                                                        </span>
                                                                    </li>
                                                                )}
                                                                {receptionRooms !== 0 && (
                                                                    <li className="specs__item">
                                                                        <svg
                                                                            width="20"
                                                                            height="14"
                                                                            className="specs__icon"
                                                                            role="img"
                                                                        >
                                                                            <title>
                                                                                Reception rooms
                                                                            </title>
                                                                            <use xlinkHref="#icon-sofa"></use>
                                                                        </svg>
                                                                        <span className="specs__text">
                                                                            {receptionRooms}
                                                                        </span>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="recently_viewed_properties__propertyCard--right">
                                                <div className="recently_viewed_properties__propertyCard__image">
                                                    <EnhancedImage image={imageUrl} alt={title} />
                                                </div>
                                            </div>
                                        </article>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                )}
            </OnVisible>
        );
    }
}

RecentlyViewedProperties.propTypes = {
    endpointUrl: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
};

export default RecentlyViewedProperties;
