import { DcBaseComponent } from '@deleteagency/dc';
import Flickity from 'flickity';

export default class FeaturedContentComponent extends DcBaseComponent {
    static getNamespace() {
        return 'featured-content';
    }

    onInit() {
        const carousel = new Flickity(this.element, {
            cellAlign: 'left',
            contain: true,
            pageDots: true,
            wrapAround: true,
            prevNextButtons: false,
            watchCSS: true,
            imagesLoaded: true,
            dragThreshold: 10,
        });

        this.carousel = carousel;
    }
}
